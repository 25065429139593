import { render, staticRenderFns } from "./ChangeAccount.vue?vue&type=template&id=e52b3ec6&scoped=true&"
import script from "./ChangeAccount.vue?vue&type=script&lang=js&"
export * from "./ChangeAccount.vue?vue&type=script&lang=js&"
import style0 from "./ChangeAccount.vue?vue&type=style&index=0&id=e52b3ec6&scoped=true&lang=css&"
import style1 from "./ChangeAccount.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e52b3ec6",
  null
  
)

export default component.exports