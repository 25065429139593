<template>
    <div class="chartset">
        <van-nav-bar 
                     :left-text="$t('toback')"
                     left-arrow
                     @click-left="back"
                     :right-text="$t('app.login')"
                     @click-right="login" />
        <div class="page member">

            <div class="cblock">
                <van-form @submit="login">
                    <van-field v-model="mt4Account"
                               type="number"
                               name="Login"
                               :label="$t('Mt4ApiTrade.account')"
                               :placeholder="$t('app.pleaseinputaccount')"
                               :rules="[{ required: true}]"
                               ref="mt4Account" />
                    <van-field v-model="pwd"
                               ref="pwd"
                               type="password"
                               name="Password"
                               :label="$t('Mt4ApiTrade.password')"
                               :placeholder="$t('app.pleaseinputpassword')"
                               :rules="[{ required: true }]" />
                </van-form>
            </div>

            
        </div>




    </div>
</template>

<script>
    import { ChangeAccount } from "@/api/mt4Api";
    import { Notify, Dialog } from 'vant';
    export default {
        data() {
            return {
                mt4Account: "",
                pwd: "",
                disabledLogin: false,

            };
        },
        computed: {},
        mounted() {
            this.$store.commit("toggleTabbarShow", false);
            this.$store.commit("toggleTabbarCurrentShow", "Mt4");

        },
        watch: {
            mt4Account(newVal, oldVal) {
                if (this.mt4Account.length > 0) {
                    this.disabledLogin = false;
                } else {
                    this.disabledLogin = true;
                }
            },
            pwd(newVal, oldVal) {
                if (this.pwd.length > 0) {
                    this.disabledLogin = false;
                } else {
                    this.disabledLogin = true;
                }
            },
        },
        created() {

        },
        beforeDestroy() { },
        methods: {
            back() {
                this.$router.back();
            },
            login() {
                if (this.disabledLogin) {
                    return;
                }
                this.disabledLogin = true;
                //localStorage.removeItem("tokenMt4");
                //localStorage.removeItem("mt4userinfo");
                let params = {
                    login: this.mt4Account,
                    password: this.pwd,
                    ip: "47.52.200.108:443",
                };

                ChangeAccount(params).then((res) => {
                    this.disabledLogin = false;
                  
                    if (res.code == 200) {
                        this.$store.commit("loginMt4", res.msg);
                        this.$store.commit("toggleTabbarShow", true);
                        this.$router.push("/settings");
                    } else {
                        Notify({ type: 'danger', message: this.$t(res.msg) });
                       // this.$refs.pwd.focus();
                    }
                });
            }


        },
    };
</script>


<style scoped>
    #app {
        background-color: #f7f7f7;
    }




    .page {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
    }

    .cblock {
        margin: 0 10px;
    }



    .margin-top {
        margin-top: 0.5rem;
    }

    .cblock:last-child {
        padding-bottom: 0.5rem;
    }

    .description {
        font-size: 12px;
    }

    .title {
        font-size: 16px;
    }
</style>
<style>
    .chartset .van-nav-bar {
        background-color: #13b5b1;
    }

    .chartset .van-nav-bar__title {
        color: #fff;
    }

    .chartset .van-nav-bar .van-icon, .chartset .van-nav-bar__text {
        color: #fff;
    }
</style>